globalThis["__sentryRewritesTunnelPath__"] = undefined;
globalThis["SENTRY_RELEASE"] = {"id":"Ak41IjV3mk_ikfxsBin3G"};
globalThis["__sentryBasePath"] = undefined;
globalThis["__rewriteFramesAssetPrefixPath__"] = "";
// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from '@sentry/nextjs';
import { beforeSend, ignoreErrors, maxValueLength } from 'shared/variables';

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN;

Sentry.init({
  dsn:
    SENTRY_DSN ||
    'https://0f7c5cabc4b04c18b73f7b7cb3b662a4@o61514.ingest.sentry.io/131996',
  // Adjust this value in production, or use tracesSampler for greater control
  tracesSampleRate: 0.01,
  ignoreErrors,
  beforeSend,
  maxValueLength,
  enabled: process.env.NODE_ENV === 'production',
  // ...
  // Note: if you want to override the automatic release value, do not set a
  // `release` value here - use the environment variable `SENTRY_RELEASE`, so
  // that it will also get attached to your source maps
});
