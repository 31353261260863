export const FEATURES = {
  client_fields: 'Custom client profile fields',
  forms: 'Forms',
  invoices: 'Invoicing',
  multiple_services: 'Multiple service bookings',
  eva_mode: 'EVA mode',
  video_calls: 'Video calls',
  promo_codes: 'Promo codes',
  client_notes: 'Client notes',
  repeat_bookings: 'Repeat bookings',
  multiple_clients: 'Multiple clients',
  attachments: 'Attachments',
  ses: 'Amazon SES',
  reports: 'Reports',
  // emguidance: 'EM Guidance',
  documents: 'Documents',
};

export const CORE_FEATURE_DESCRIPTIONS = [
  'Reminders and notifications',
  'Message replies',
  'Online client bookings',
  'Embed on your website',
  'Sync with external calendar',
];

export const languages = {
  en: 'English',
  fr: 'Français',
};

export const SYSTEM_CURRENCIES = ['ZAR', 'USD'];
export const IN_READ_ONLY_MODE = false;
export const ONBOARDING_ENABLED = true;

export const EMPTY_ARRAY = Object.freeze([]);
export const EMPTY_OBJECT = Object.freeze({});

// export const ENABLED_TEMPLATES = __TARGET_ENV__ !== 'production';
